/* @jsx jsx */
import React, {useState} from 'react';
import {jsx, css} from '@emotion/react';
import {graphql} from "gatsby";
import Layout from "@containers/layout";
import GraphQLErrorList from "@components/graphql-error-list";
import Seo from "@components/seo";
import ContentWrapper from "@molecules/contentWrapper";
import Header from "@organisms/header";
import {mapEdgesToNodes} from "@utils/helpers";
import {breakpoints, colors, fonts, fontWeights, unitConverter} from "@utils/base";
import Heading1 from "@atoms/headings/heading1/heading1";
import PortableText from "@atoms/portableText";
import Image from "@atoms/image";
import Heading2 from "@atoms/headings/heading2/heading2";
import Videos from "@organisms/videos";

const get = require('lodash.get')

const PilotPage = ({data, errors}) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const {site} = data;
  let {videos, pilot} = data;
  const {seo, global, header, footer} = site;
  videos = mapEdgesToNodes(videos);
  pilot = mapEdgesToNodes(pilot)[0];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [videosToShow, setVideosToShow] = useState([]);
  const updateVideos = (page, range, items) => { setVideosToShow(items); };

  const titleCss = css`
    margin-bottom: ${unitConverter('20px')};
    width: 100%;
  `;

  const bioWrapperCss = css`
    color: ${colors.text};
    font-family: ${fonts.signo};
    display: flex;
    flex-wrap: wrap;
    margin-left: ${unitConverter('20px')};

    ${breakpoints.mobile} {
      margin-left: 0;
    }
  `;

  const bioLeftSideCss = css`
    width: 30%;
    padding-right: ${unitConverter('20px')};

    figure {
      width: 100%;
      max-width: 50%;
      float: left;
      margin-right: ${unitConverter('20px')};
    }

    p {
      font-weight: ${fontWeights.light};
    }

    strong {
      font-weight: normal;
      font-family: ${fonts.signo};
      font-weight: ${fontWeights.bold};
    }

    ${breakpoints.tabletLandscape} {
      width: 100%;
    }

    ${breakpoints.mobile} {
      padding-right: 0;
    }
  `;

  const bioRightSideCss = css`
    width: 70%;
    padding-left: ${unitConverter('20px')};

    h2 {
      margin-bottom: ${unitConverter('20px')};
    }

    h3 {
      font-size: ${unitConverter('16px')};
    }

    ${breakpoints.tabletLandscape} {
      width: 100%;
      padding-left: 0;
      margin-top: ${unitConverter('40px')};
    }
  `;

  const pilotImageCss = css`
    padding: ${unitConverter('10px')};
    border: 1px solid ${colors.lightGray};
    margin-bottom: ${unitConverter('20px')};
    overflow: hidden;
  `;

  const videosCss = css`
    > div {
      ${breakpoints.mobile} {
        padding: 0;
      }
    }
    .video-info {
      padding-left: 0;
      max-width: 100%;
    }
  `;

  const pilotIconCss = css`
    height: ${unitConverter('30px')};
    width: ${unitConverter('30px')};
    overflow: hidden;
    display: inline-block;
    margin-right: ${unitConverter('20px')};
    position: relative;
    top: ${unitConverter('2px')};
    border-radius: ${unitConverter('5px')};
  `;

  const ogImage = pilot.avatar ? pilot.avatar : {};

  return (
    <Layout footerSettings={footer} bgImage={get(global.backgroundImage, 'asset.fluid.src')}>
      <Seo
        title={pilot.handle}
        description={pilot._rawBio[0].children[0].text}
        keywords={seo.keywords}
        ogType='website'
        image={ogImage}
      />
      <ContentWrapper>
        <Header title={seo.title} {...header} logoWidth={400} showSearch={false} />
        <div css={bioWrapperCss}>
          <Heading1 css={titleCss}>
            {pilot.avatar && (
              <div css={pilotIconCss}>
                <Image src={pilot.avatar} alt={pilot.handle} maxWidth={40} />
              </div>
            )}
            {pilot.handle}
          </Heading1>
          <div css={bioLeftSideCss}>
            {pilot.photo && (
              <div css={pilotImageCss}>
                <Image src={pilot.photo} alt={`${pilot.fname} ${pilot.lname}`} maxWidth={400} />
              </div>
            )}
            {pilot._rawBio && <PortableText blocks={pilot._rawBio} />}
          </div>
          <div css={bioRightSideCss}>
            {(pilot.handle && videos.length === 0) && <Heading2>{pilot.handle} has No videos</Heading2>}
            {(pilot.handle && videos.length === 1) && <Heading2>{pilot.handle} has 1 video</Heading2>}
            {(pilot.handle && videos.length > 1) && <Heading2>{pilot.handle} has {videos.length} videos</Heading2>}
            <Videos videos={videos} perPage={20} css={videosCss} videosToShow={videosToShow} action={updateVideos} />
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  );
};

export default PilotPage;

export const query = graphql`
  query PilotPageQuery($slug: String!){
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      seo {
        title
        description
        keywords
      }
      header {
        tagline
        logo {
          asset {
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      global {
        backgroundImage {
          asset {
            id
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      footer {
        address
        tagline
        listOfLinks {
          __typename
          ... on SanityLink {
            linkText
            linkUrl
            _type
          }
        }
      }
    }
    pilot: allSanityPilot(filter: {handle: {eq: $slug}}) {
      edges {
        node {
          _id
          handle
          fname
          lname
          _rawBio(resolveReferences: {maxDepth: 10})
          avatar {
            asset {
              _id
              fluid {
                base64
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
          photo {
            asset {
              _id
              fluid {
                base64
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
    videos: allSanityVideo(filter: {pilot: {handle: {eq: $slug}}}, sort: {fields: _updatedAt, order: DESC}) {
      edges {
        node {
          id
          pilot {
            _id
            handle
            social {
              facebook
              instagram
            }
          }
          title
          slug {
            current
          }
          thumbnail {
            asset {
              _id
              fluid {
                base64
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`;
