/* @jsx jsx */
import React, {useState, useEffect} from 'react';
import {css, jsx} from '@emotion/react';
import Video from "@molecules/video";
import {colors, breakpoints, unitConverter, transitions, fonts, fontWeights} from "@utils/base";
import Pagination from 'react-sanity-pagination';

const videoCss = css`
  width: 100%;
  max-width: 25%;
  padding: ${unitConverter('10px')};
  transition: all ${transitions.fast} ease-in-out;
  margin-bottom: ${unitConverter('20px')};
  border: ${unitConverter('1px')} solid transparent;

  .main-image-wrapper {
    overflow: hidden;
    background-color: ${colors.background};
    border: ${unitConverter('1px')} solid ${colors.lightGray};
    .gatsby-image-wrapper {
      transition: all ${transitions.fast} ease-in-out;
    }
  }

  &:hover {
    background-color: ${colors.black};
    border: ${unitConverter('1px')} solid ${colors.lightGray};

    .main-image-wrapper {
      border: ${unitConverter('1px')} solid ${colors.lightGray};
      overflow: hidden;
    }

    &.even {
      .main-image-wrapper {
        .gatsby-image-wrapper {
          transform: scale(1.5) rotate(-2.5deg);
        }
      }
    }

    &.odd {
      .main-image-wrapper {
        .gatsby-image-wrapper {
          transform: scale(1.5) rotate(2.5deg);
        }
      }
    }
  }

  ${breakpoints.tabletLandscape} {
    max-width: 33%;
  }

  ${breakpoints.tabletPortrait} {
    max-width: 50%;
  }

  ${breakpoints.mobile} {
    max-width: 100%;
  }
`;

const videosWrapperCSS= css`
  margin: ${unitConverter('0')};
  padding: ${unitConverter('10px 10px 0 10px')};
  background-color: ${colors.darkGray};
  border: ${unitConverter('1px')} solid ${colors.lightGray};
  margin-bottom: ${unitConverter('25px')};
`;

const videosCss = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-content: flex-start;
`;

const searchTextCSS = css`
  color: ${colors.lightGray};
  font-family: ${fonts.signo};
  font-weight: ${fontWeights.normal};
  font-size: ${unitConverter('24px')};
  padding: ${unitConverter('10px')};
`;

const Videos = ({videos, perPage, className, searchTerm, action, videosToShow}) => {

  const paginationCss = css`
    list-style: none outside;
    text-align: center;
    width: 100%;
    margin: ${unitConverter('0 0 40px')};
    display: ${searchTerm && searchTerm !== ':::RESET:::' ?  'none' : 'block'};

    li {
      display: inline-block;
      padding: ${unitConverter('5px')};
      font-family: ${fonts.tektonPro};

      button {
        padding: ${unitConverter('10px')};
        border: none;
        border-radius: ${unitConverter('5px')};
        background-color: ${colors.darkGray};
        color: ${colors.lightGray};
        border: ${unitConverter('1px')} solid ${colors.darkGray};
        transition: all ${transitions.fast} ease-in-out;
        outline: none;
        font-family: ${fonts.signo};
        font-size: ${unitConverter('12px')};

        &:not([disabled]) {
          cursor: pointer;

          &:hover {
            color: ${colors.lightGrayHover};
            border: ${unitConverter('1px')} solid ${colors.lightGray};
          }
        }

        &:disabled {
          opacity: .4;
        }

        &.active {
          color: ${colors.text};
          border: ${unitConverter('1px')} solid ${colors.lightGray};
        }
      }
    }
  `;

  const getVideosPronunciation = () => {
    return videosToShow.length > 1 ? 'videos' : 'video';
  }

  return (
    <>
      <div css={videosWrapperCSS}>
        {searchTerm && searchTerm !== ':::RESET:::' && (
          <h3 css={searchTextCSS}>
            {videosToShow.length} {getVideosPronunciation()} found for "{searchTerm}"
          </h3>
        )}
        <div css={videosCss} className={className}>
          {videosToShow.map((video, index) => (
            <Video
              css={videoCss}
              key={video.id}
              className={index % 2 === 0 ? 'odd' : 'even'}
              {...video}
            />
          ))}
        </div>
      </div>
      <Pagination
        items={videos}
        action={action}
        postsPerPage={perPage}
        nextButton={true}
        prevButton={true}
        prevButtonLabel={'Previous'}
        nextButtonLabel={'Next'}
        css={paginationCss}
        searchTerm={searchTerm}
      />
    </>

  );
};

export default Videos;
