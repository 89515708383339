import React from 'react';
import styled from '@emotion/styled';
import {breakpoints, colors, fonts, fontWeights, unitConverter} from "@utils/base";

const Heading1 = ({children, className}) => {
  className = className || '';
  const Heading = styled.h1`
    font-family: ${fonts.tektonPro};
    font-weight: ${fontWeights.normal};
    font-size: ${unitConverter('38px')};
    color: ${colors.headings};
    
    ${breakpoints.mobile} {
      font-size: ${unitConverter('24px')};
    }
  `;

  return <Heading className={className}>{children}</Heading>;
};

export default Heading1;
